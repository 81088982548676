<template>
<div class="main">
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">
      <template v-slot:button>
        <button class="btn btn-bg-none btn-close" @click="destroy()">
          <img class="icon-16" src="/static/images/icon/back.svg">
        </button>
      </template>

      <template v-slot:page-name>打印订单</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" v-print="'#card-receipt'">
          <span class="mr-2">打印</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/print.svg">
        </button>
      </template>
    </TopMenu>

    <div class="content bg-25 p-2 flex-1">
      <div id="card-receipt">
        <div class="d-flex justify-content-between">
          <div>
            <div class="brand-logo">
              <img src="/static/images/img/logo.svg">
            </div>
            <h3 class="m-0">佛山市东徕新材料科技有限公司 | {{ order.type == 1 ? '送货单' : '退货单' }}</h3>
            <div class="font-12">业务电话：0757-85550100 | 0757-85550800</div>
          </div>
          <div>
            <div class="d-inline-block mr-2">
              <h3 class="text-slim m-0"><span>制单日期</span><span class="ml-1">{{ order.deliver_at }}</span></h3>
            </div>
            <div class="d-inline-block">
              <h3 class="m-0"><span>单号</span><span class="order-number ml-1">{{ order.number }}</span></h3>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-bottom mt-1 mb-2p">
          <div><h3 class="m-0"><span class="text-slim">客户</span> {{ order.customer_name }}</h3></div>
          <div>白单存根/蓝单统计/红单收款/黄单客户</div>
        </div>
        <table class="table">
          <thead class="thead">
            <tr>
              <th class="table-cell" style="width:27%;">产品</th>
              <th class="table-cell" style="width:12%;">规格(kg/件)</th>
              <th class="table-cell" style="width:8%;">件数</th>
              <th class="table-cell" style="width:8%;">单位</th>
              <th class="table-cell" style="width:12%;">数量</th>
              <th class="table-cell" style="width:13%;">单价(元)</th>
              <th class="table-cell" style="width:20%;">金额(元)</th>
            </tr>
          </thead>
          <tbody class="tbody" v-if="order.list">
            <tr v-for="product in order.list" :key="product">
              <td class="table-cell" style="width:27%;">{{ product.new_name }}</td>
              <td class="table-cell" style="width:12%;">{{ product.specification }}</td>
              <td class="table-cell" style="width:8%;">{{ $math.Div(product.quantity, product.specification) }}</td>
              <td class="table-cell" style="width:8%;">kg</td>
              <td class="table-cell" style="width:12%;">{{ product.quantity }}</td>
              <td class="table-cell" style="width:13%;">{{ product.price }}</td>
              <td class="table-cell" style="width:20%;">{{ $math.formatMoney($math.Mul(product.quantity, product.price), 2) }}</td>
            </tr>
            <template v-if="order.list.length < 6">
              <tr class="table-row visibility-hidden" v-for="index of 5-order.list.length" :key="index">
                <td class="table-cell" style="width:27%;">~</td>
                <td class="table-cell" style="width:12%;"></td>
                <td class="table-cell" style="width:8%;"></td>
                <td class="table-cell" style="width:8%;"></td>
                <td class="table-cell" style="width:12%;"></td>
                <td class="table-cell" style="width:13%;"></td>
                <td class="table-cell" style="width:20%;"></td>
              </tr>
            </template>
          </tbody>
        </table>

        <table class="table-total mb-2">
          <thead>
            <tr>
              <th class="text-slim" style="width:27%; font-size: 18px;">合计金额(人民币)</th>
              <th style="width:40%"><h4 class="m-0">{{ $math.smallToBig(totle()) }}</h4></th>
              <th style="width:33%"><h4 class="m-0">￥{{ $math.formatMoney(totle(), 2) }}</h4></th>
            </tr>
          </thead>
        </table>

        <div class="d-flex justify-content-between font-14">
          <div style="width:50%;">
            <div class="mb-1"><span class="text-slim">供货方备注</span> {{ order.remarks }}</div>
            <div><span class="text-slim">制单人</span> {{ order.prepared_by }}</div>
            <div><span class="text-slim">送货司机</span> {{ order.driver }}</div>
          </div>
          <div style="width:50%;">
            <div class="mb-1"><span class="text-slim">购货方备注</span></div>
            <div><span class="text-slim">收货人</span></div>
            <div><span class="text-slim">(盖章)</span></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import print from 'vue3-print-nb'

export default {
  name: 'OrderPrint',

  data() {
    return {
      loading: true,
      allowAttack: false,
      order: {}
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    this.getOrder()
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
  },

  directives: {
    print   
  },

  methods: {

    //更新订单
    getOrder() {
      this.$axios.get('/api/order/'+this.$route.params.id, {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            console.log(res.data)
            this.order = res.data
            this.loading = false
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    destroy() {
      this.$emit('sendMsg', {
        transition_name: 'down'
      })

      this.$router.push({name: 'OrderList'})
    },

  },

  computed: {
    totle() {
      let _this = this
      return () => {
        let totalPrice = 0;
        if (this.order.list) {
          this.order.list.forEach(function(item) {
            let rowPrice = _this.$math.Mul(item.quantity, item.price)
            totalPrice = _this.$math.Add(totalPrice, rowPrice)
          })
        }
        return totalPrice
      }
    },
  },
}
</script>

<style scoped>
#card-receipt {
  color: #000;
  padding: 32px;
  background-color: #fff;
}

#card-receipt .thead {
  font-size: 14px;
  font-weight: 300;
}
#card-receipt .table{
  font-size: 20px;
  font-weight: 400;
}

table{
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  border-collapse: collapse;
}
table tbody tr td, table thead tr th{
  border: 1px solid #000;
  padding: .3rem;
  vertical-align: middle;
}
table thead tr th {
  font-weight: 400;
}

.table-total {
  text-align: left;
}

.brand-logo img {
  height: 40px;
}

.order-number {
  font-size: 36px;
  font-family: "Times New Roman";
  font-weight: 900;
}

.visibility-hidden {
  visibility: hidden;
}


</style>
